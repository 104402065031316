import styles from "@/styles/Home.module.css";
import { useRouter } from "next/router";
import en from "../../locales/en";
import ar from "../../locales/ar";
import { Box, Typography } from "@mui/material";
import { colors } from "../utils/const";
import dynamic from "next/dynamic";
import ipapi from "ipapi.co";

// Dynamically import components
const Title = dynamic(() => import("@/components/mini-components/Title.jsx"));
const ImageSection = dynamic(() => import("../sections/home/ImageSection.jsx"));
const IconsSection = dynamic(() => import("../sections/home/IconsSection.jsx"));
const Popup = dynamic(() => import("@/components/Popup"));
const Testimonialcard = dynamic(() => import("@/components/Testimonialcard"));
const Steps = dynamic(() => import("../components/steps/stepsComp"));
const CoverSection = dynamic(() => import("../sections/home/CoverSection.jsx"));
const VerticalCarousel = dynamic(() =>
  import("../components/VerticalCarousel.jsx")
);
const ContactPaySection = dynamic(() =>
  import("../sections/home/ContactPaySection.jsx")
);

const PageHead = dynamic(() => import("@/components/PageHead"));
import { useDispatch, useSelector } from "react-redux";
import { userGeoAction } from "../Redux/store";

export default function Home() {
  const dispatch = useDispatch();
  const { data: userGeoData } = useSelector((state) => state.userGeo);

  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;

  let dir = locale === "en" ? "left" : "right";

  return (
    <>
      <PageHead
        title={t.pages_main_tags.home.title}
        description={t.pages_main_tags.home.meta_desc}
      />
      <main className={styles.main}>
        <Typography
          sx={{
            visibility: "hidden",
            width: "0px",
            height: "0px",
            fontSize: "10px",
          }}
          variant="h1"
        >
          {t.pages_main_tags.home.h1}
        </Typography>
        <Typography
          sx={{
            visibility: "hidden",
            width: "0px",
            height: "0px",
            fontSize: "1px",
          }}
          variant="h2"
        >
          {t.pages_main_tags.home.h2}
        </Typography>
        <CoverSection></CoverSection>
        <ImageSection></ImageSection>
        <IconsSection></IconsSection>
        <Steps />
        <ContactPaySection></ContactPaySection>
        <Box>
          <Box
            className={`vertical-safe-padding section-safe-padding-${dir} py-3`}
            sx={{
              background: colors.bg_grey,
            }}
          >
            <Box sx={{ mb: "-30px" }}>
              <Title title={t.general.reviewTitle} />
            </Box>
          </Box>
          <VerticalCarousel
            itemsArray={t.general.reviews_individuals}
            Component={Testimonialcard}
            slidesPerView={3}
            center={true}
          ></VerticalCarousel>
          <Popup />
        </Box>
      </main>
    </>
  );
}
